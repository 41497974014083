import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FaBaseballBall,
  FaBasketballBall,
  FaFootballBall,
  FaHockeyPuck,
  FaTools,
  FaChevronDown,
} from 'react-icons/fa';
import './Navbar.css';

function Navbar({ darkMode, toggleDarkMode, isAuthenticated, setIsAuthenticated }) {
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && menuActive) {
        closeMenu();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [menuActive]);

  const closeMenu = () => {
    setMenuActive(false);
    setOpenDropdown(null);
  };

  const handleLogoClick = () => {
    navigate('/');
    closeMenu();
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleDropdownToggle = (dropdownName) => {
    setOpenDropdown(prev => prev === dropdownName ? null : dropdownName);
  };

  const handleAuthAction = () => {
    closeMenu();
    if (isAuthenticated) {
      window.location.href = 'https://zeusanalytics.org/accounts/logout';
    } else {
      window.location.href = 'https://zeusanalytics.org/accounts/dubclub/login/';
    }
  };

  const handleMobileLinkClick = (e) => {
    e.stopPropagation();
    closeMenu();
  };

  return (
    <>
      <nav className="navbar-container">
        <div className="navbar-left">
          <Link to="/" onClick={handleLogoClick}>
            <img
              src="/zeusanalyticslogo2_BG_resized.png"
              alt="Zeus Analytics"
              className="navbar-home-icon"
            />
          </Link>
          <span className="navbar-title">Zeus Analytics</span>
        </div>

        <div className="navbar-hamburger" onClick={toggleMenu}>
          <span style={{ transform: menuActive ? 'rotate(45deg) translate(5px, 6px)' : '' }}></span>
          <span style={{ opacity: menuActive ? 0 : 1 }}></span>
          <span style={{ transform: menuActive ? 'rotate(-45deg) translate(5px, -6px)' : '' }}></span>
        </div>

        <div className={`navbar-right ${menuActive ? 'active' : ''}`}>
          <div className="navbar-beta-label">BETA</div>

          <div className="navbar-dark-mode-toggle">
            <label className="navbar-switch">
              <input
                type="checkbox"
                checked={darkMode}
                onChange={toggleDarkMode}
              />
              <span className="navbar-slider"></span>
            </label>
            <span className="navbar-dark-mode-text">{darkMode ? 'Dark' : 'Light'}</span>
          </div>

          {/* MLB Dropdown */}
          <div className="navbar-dropdown">
            <button className="navbar-dropbtn" onClick={() => handleDropdownToggle('MLB')}>
              <FaBaseballBall /> MLB <FaChevronDown size={14} />
            </button>
            <div className={`navbar-dropdown-content ${openDropdown === 'MLB' ? 'show' : ''}`}>
              <Link to="/mlb/the-script" onClick={handleMobileLinkClick}>The Script</Link>
              <Link to="/mlb/exit-velocity-tracker" onClick={handleMobileLinkClick}>Exit Velocity Tracker</Link>
              <Link to="/mlb/whos-hot" onClick={handleMobileLinkClick}>Who's Hot</Link>
              <Link to="/mlb/starting-pitcher-stats" onClick={handleMobileLinkClick}>Starting Pitcher Stats</Link>
              <Link to="/mlb/daily-lineup-stats" onClick={handleMobileLinkClick}>Daily Lineup Stats</Link>
              <Link to="/mlb/inning-first-stats" onClick={handleMobileLinkClick}>1st Inning Stats</Link>
              <Link to="/mlb/park-factors" onClick={handleMobileLinkClick}>Park Factors</Link>
              <Link to="/mlb/scores" onClick={handleMobileLinkClick}>Scores</Link>
              <Link to="/mlb/definition" onClick={handleMobileLinkClick}>Definitions</Link>
            </div>
          </div>

          {/* NBA Dropdown */}
          <div className="navbar-dropdown">
            <button className="navbar-dropbtn" onClick={() => handleDropdownToggle('NBA')}>
              <FaBasketballBall /> NBA <FaChevronDown size={14} />
            </button>
            <div className={`navbar-dropdown-content ${openDropdown === 'NBA' ? 'show' : ''}`}>
              <Link to="/nba/stat-tracker" onClick={handleMobileLinkClick}>Stat Tracker</Link>
              <Link to="/nba/whos-hot" onClick={handleMobileLinkClick}>Who's Hot</Link>
              <Link to="/nba/awards" onClick={handleMobileLinkClick}>Daily Awards</Link>
              <Link to="/nba/three-pointer-hub" onClick={handleMobileLinkClick}>Three Pointer Hub</Link>
              <Link to="/nba/first-basket" onClick={handleMobileLinkClick}>First Basket Predictions</Link>
            </div>
          </div>

          {/* NFL Dropdown */}
          <div className="navbar-dropdown">
            <button className="navbar-dropbtn" onClick={() => handleDropdownToggle('NFL')}>
              <FaFootballBall /> NFL <FaChevronDown size={14} />
            </button>
            <div className={`navbar-dropdown-content ${openDropdown === 'NFL' ? 'show' : ''}`}>
              <Link to="/nfl/top-td-predictions" onClick={handleMobileLinkClick}>TD Predictions</Link>
              <Link to="/nfl/top-td-longshots" onClick={handleMobileLinkClick}>TD Longshots</Link>
              <Link to="/nfl/defensive-team-stats" onClick={handleMobileLinkClick}>Defensive Stats</Link>
              <Link to="/nfl/target-shares-and-defensive-coverages" onClick={handleMobileLinkClick}>Coverage Matrix</Link>
              <Link to="/nfl/nfl-hit-rates" onClick={handleMobileLinkClick}>Hit Rates</Link>
            </div>
          </div>

          {/* Tools Dropdown */}
          <div className="navbar-dropdown">
            <button className="navbar-dropbtn" onClick={() => handleDropdownToggle('Tools')}>
              <FaTools /> Tools <FaChevronDown size={14} />
            </button>
            <div className={`navbar-dropdown-content ${openDropdown === 'Tools' ? 'show' : ''}`}>
              <Link to="/sportsbooks/arbitrage-calculator" onClick={handleMobileLinkClick}>Arbitrage Calculator</Link>
              <Link to="/sportsbooks/odds-converter" onClick={handleMobileLinkClick}>Odds Converter</Link>
              <Link to="/faq" onClick={handleMobileLinkClick}>FAQ</Link>
            </div>
          </div>

          {/* Auth Button */}
          <button
            className="navbar-dropbtn"
            onClick={handleAuthAction}
            style={{ 
              background: 'var(--button-background)', 
              color: 'var(--button-text)',
              fontSize: '1.1rem',
              marginTop: '15px'
            }}
          >
            {isAuthenticated ? 'Logout' : 'Login'}
          </button>
        </div>

        {menuActive && (
          <div className="navbar-overlay active" onClick={closeMenu}></div>
        )}
      </nav>
    </>
  );
}

export default Navbar;