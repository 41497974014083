// src/components/NBAFirstBasketPredictions.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import FlippableCard from "./FlippableCard";
import "./NBAFirstBasketPredictions.css";

const DEFAULT_PROFILE_URL = "https://s3.amazonaws.com/37assets/svn/765-default-avatar.png";

// ESPN Team ID mapping (add more as needed)
const ESPN_TEAM_IDS = {
  'ATL': 1,    'BOS': 2,    'BKN': 17,   'CHA': 30,   'CHI': 4,
  'CLE': 5,    'DAL': 6,    'DEN': 7,    'DET': 8,    'GSW': 9,
  'HOU': 10,   'IND': 11,   'LAC': 12,   'LAL': 13,   'MEM': 29,
  'MIA': 14,   'MIL': 15,   'MIN': 16,   'NOP': 3,    'NYK': 18,
  'OKC': 25,   'ORL': 19,   'PHI': 20,   'PHX': 21,   'POR': 22,
  'SAC': 23,   'SAS': 24,   'TOR': 28,   'UTA': 26,   'WAS': 27
};

export default function NBAFirstBasketPredictions() {
  const [predictions, setPredictions] = useState([]);
  const [playerMap, setPlayerMap] = useState({});
  const [gameTimes, setGameTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // Get current Eastern Time date string in YYYYMMDD format
  const getEasternDate = () => {
    const now = new Date();
    const etOptions = { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit' };
    const [month, day, year] = now.toLocaleDateString('en-US', etOptions).split('/');
    return `${year}${month}${day}`;
  };

  // Fetch ESPN schedule data
  const fetchGameTimes = async () => {
    try {
      const dateString = getEasternDate();
      const response = await axios.get(
        `https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard?dates=${dateString}`
      );
      
      const games = response.data.events.reduce((acc, event) => {
        const awayTeam = event.competitions[0].competitors.find(t => t.homeAway === 'away');
        const homeTeam = event.competitions[0].competitors.find(t => t.homeAway === 'home');
        
        const key = `${awayTeam.team.abbreviation}-${homeTeam.team.abbreviation}`;
        const date = new Date(event.date);
        
        // Format time in Eastern Time
        const options = { 
          hour: 'numeric', 
          minute: '2-digit', 
          hour12: true, 
          timeZone: 'America/New_York' 
        };
        
        acc[key] = {
          time: date.toLocaleTimeString('en-US', options) + ' ET',
          status: event.status.type.description
        };
        
        return acc;
      }, {});
      
      setGameTimes(games);
    } catch (error) {
      console.error("Error fetching game times:", error);
      setGameTimes({});
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get("/profile/");
        const { access_token } = response.data || {};
        const isSubscribed = await checkSubscriptionStatus(access_token);
        setIsAuthenticated(isSubscribed);
        if (!isSubscribed) window.location.href = "https://dubclub.win/r/Zeus_Analytics/";
      } catch (error) {
        console.error("Auth error:", error);
        window.location.href = "https://zeusanalytics.org/accounts/dubclub/login/";
      }
    };
    checkAuth();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchData = async () => {
      try {
        // Fetch game times first
        await fetchGameTimes();

        // Fetch player pictures
        const playersResponse = await axios.get('/api/nba-player-pictures/', {
          params: { all: true, sort: 'player_name' }
        });
        
        const newPlayerMap = playersResponse.data.results.reduce((acc, player) => {
          const [firstName, ...lastNameParts] = player.player_name.split(' ');
          const lastName = lastNameParts.join(' ');
          const key = `${lastName.toLowerCase()}-${firstName[0].toLowerCase()}`;
          acc[key] = player.profile_picture;
          return acc;
        }, {});

        // Fetch predictions
        const predictionsResponse = await axios.get("/api/nba-multi-factor-first-basket");
        const enhanced = predictionsResponse.data.map(pred => {
          const gameKey = `${pred.away_team}-${pred.home_team}`;
          const gameInfo = gameTimes[gameKey] || { time: "Time TBD", status: "Scheduled" };
          
          return {
            ...pred,
            profile_picture: findPlayerImage(pred.predicted_player_name, newPlayerMap),
            gameTime: gameInfo.time,
            gameStatus: gameInfo.status
          };
        });

        setPlayerMap(newPlayerMap);
        setPredictions(enhanced);
      } catch (err) {
        console.error("Data fetch error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated, gameTimes]);

  const findPlayerImage = (abbrName, map) => {
    if (!abbrName) return DEFAULT_PROFILE_URL;
    
    try {
      // Handle various name formats (e.g., "D.Bane", "A. Reaves", "J.Randle")
      const normalized = abbrName.replace(/\./g, '. ').replace(/\s+/g, ' ');
      const [initialPart, ...lastNameParts] = normalized.split(' ');
      const lastName = lastNameParts.join(' ');
      const cleanInitial = initialPart.replace('.', '').toLowerCase();
      const mapKey = `${lastName.toLowerCase().replace(/'/g, '')}-${cleanInitial}`;
      
      return map[mapKey] || DEFAULT_PROFILE_URL;
    } catch (e) {
      console.warn("Name parsing error:", abbrName, e);
      return DEFAULT_PROFILE_URL;
    }
  };

  // Subscription check function remains the same
  async function checkSubscriptionStatus(token) {
    try {
      const response = await axios.get("/api/check-subscription/", {
        params: { token },
      });
      const subscriptions = response.data;
      const currentTime = new Date().toISOString();
      return subscriptions.some(
        (sub) =>
          sub.price.product.capper.id === 320906 &&
          sub.paid_until > currentTime
      );
    } catch (error) {
      console.error("Error checking subscription:", error);
      return false;
    }
  }

  // Loading states remain the same
  if (isAuthenticated === null) {
    return <div className="fbp-loading">Checking subscription...</div>;
  }

  if (loading) {
    return <div className="fbp-loading">Loading first basket predictions...</div>;
  }

  return (
    <div className="fbp-container">
      <h1>NBA First Basket Predictions</h1>
      <div className="fbp-grid">
        {predictions.map((pred) => (
          <div key={pred.id} className="fbp-card-wrapper">
            <FlippableCard
              teams={{ team1: pred.away_team, team2: pred.home_team }}
              playerName={pred.predicted_player_name}
              playerImage={pred.profile_picture}
              aiScore={pred.ai_score?.toFixed(2) || "N/A"}
              gameTime={pred.gameTime}
              gameStatus={pred.gameStatus}
            />
          </div>
        ))}
      </div>
    </div>
  );
}