import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import ExitVelocityTracker from './components/ExitVelocityTracker';
import WhosHot from './components/WhosHot';
import StartingPitcherStats from './components/StartingPitcherStats';
import DailyLineupStats from './components/DailyLineupStats';
import Scores from './components/Scores';
import ArbitrageCalculator from './components/ArbitrageCalculator';
import OddsConverter from './components/OddsConverter';
import MlbOdds from './components/mlbodds';
import ParkFactors from './components/ParkFactors';
import MLBDefinitions from './components/MLBDefinitions';
import InningFirstStats from './components/InningFirstStats';
import TopHRPredictions from './components/TopHRPredictions';
import DefensiveStats from './components/DefensiveNFLStats';
import TopTDPredictions from './components/TopTDPredictions';
import TopTDLongshots from './components/TopTDLongshots';
import CoverageMatrix from './components/CoverageMatrix';
import NflHitRates from './components/NFLHitRates';
import WhosHotNBA from './components/WhosHotNBA';
import FAQ from './components/FAQ';
import NBAPlayerStatsTracker from './components/NBAPlayerStatsTracker';
import AwardsPage from './components/AwardsPage';
import NbaThreePointerHub from './components/NbaThreePointerHub';
import NBAFirstBasketPredictions from './components/NBAFirstBasketPredictions';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [darkMode, setDarkMode] = useState(
    () => JSON.parse(localStorage.getItem('darkMode')) || false
  );

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch('/api/auth/status');
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
      } catch (error) {
        console.error('Error fetching auth status:', error);
      }
    };
    checkAuthStatus();
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(prev => {
      const newMode = !prev;
      localStorage.setItem('darkMode', JSON.stringify(newMode));
      return newMode;
    });
  };

  return (
    <Router>
      <div className={`app-container ${darkMode ? 'dark-mode' : ''}`}>
        <Navbar
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />
        
        <main className="app-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mlb/exit-velocity-tracker" element={<ExitVelocityTracker />} />
            <Route path="/mlb/whos-hot" element={<WhosHot />} />
            <Route path="/mlb/starting-pitcher-stats" element={<StartingPitcherStats />} />
            <Route path="/mlb/daily-lineup-stats" element={<DailyLineupStats />} />
            <Route path="/mlb/scores" element={<Scores />} />
            <Route path="/mlb/SECRETODDS" element={<MlbOdds />} />
            <Route path="/mlb/definition" element={<MLBDefinitions />} />
            <Route path="/mlb/park-factors" element={<ParkFactors />} />
            <Route path="/mlb/inning-first-stats" element={<InningFirstStats />} />
            <Route path="/mlb/the-script" element={<TopHRPredictions />} />
            <Route path="/nba/stat-tracker" element={<NBAPlayerStatsTracker />} />
            <Route path="/nba/whos-hot" element={<WhosHotNBA />} />
            <Route path="/nba/awards" element={<AwardsPage />} />
            <Route path="/nba/three-pointer-hub" element={<NbaThreePointerHub />} />
            <Route path="/nba/first-basket" element={<NBAFirstBasketPredictions />} />
            <Route path="/nba" element={<Navigate to="/nba/awards" replace />} />
            <Route path="/sportsbooks/arbitrage-calculator" element={<ArbitrageCalculator />} />
            <Route path="/sportsbooks/odds-converter" element={<OddsConverter />} />
            <Route path="/nfl/top-td-predictions" element={<TopTDPredictions />} />
            <Route path="/nfl/top-td-longshots" element={<TopTDLongshots />} />
            <Route path="/nfl/defensive-team-stats" element={<DefensiveStats />} />
            <Route path="/nfl/target-shares-and-defensive-coverages" element={<CoverageMatrix />} />
            <Route path="/nfl/nfl-hit-rates" element={<NflHitRates />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;