// src/components/ThreePointerFlippableCard.jsx
import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import "./ThreePointerFlippableCard.css";

const TEAM_COLORS = {
  ATL: "#E03A3E",
  BOS: "#008348",
  BKN: "#000000",
  CHA: "#00788C",
  CHI: "#CE1141",
  CLE: "#6F263D",
  DAL: "#0053BC",
  DEN: "#0E2240",
  DET: "#1D42BA",
  GSW: "#1D428A",
  HOU: "#CE1141",
  IND: "#002D62",
  LAC: "#C8102E",
  LAL: "#552583",
  MEM: "#5D76A9",
  MIA: "#98002E",
  MIL: "#00471B",
  MIN: "#0C2340",
  NOP: "#0C2340",
  NY:  "#006BB6",
  NYK: "#006BB6",
  OKC: "#007AC1",
  ORL: "#0077C0",
  PHI: "#006BB6",
  PHX: "#E56020",
  POR: "#E03A3E",
  SAC: "#5A2D81",
  SA:  "#000000",
  SAS: "#000000",
  TOR: "#CE1141",
  UTA: "#002B5C",
  WSH: "#002B5C"
};

const DEFAULT_PROFILE_URL = "https://s3.amazonaws.com/37assets/svn/765-default-avatar.png";

function ThreePointerFlippableCard({
  teams = { team1: "NYK", team2: "TOR" },
  playerImage,
  playerName,
  aiScore = "0.00",
  gameTime = "7:30PM ET",
  gameStatus = "Scheduled",
  propText = "" // e.g., "Over 4.5 3PT"
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 1 : 0,
    transform: `perspective(600px) rotateY(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const team1Color = TEAM_COLORS[teams.team1] || "#333";
  const team2Color = TEAM_COLORS[teams.team2] || "#666";
  const effectiveImage = playerImage || DEFAULT_PROFILE_URL;

  return (
    <div
      className="ThreePointerFlipCardContainer"
      onClick={() => setIsFlipped((prev) => !prev)}
    >
      {/* Front Side */}
      <animated.div
        className={`ThreePointerFrontSide ${isFlipped ? "IgnorePointer" : ""}`}
        style={{ opacity: opacity.to((o) => 1 - o), transform }}
      >
        <div className="ThreePointerCard">
          <div
            className="ThreePointerTeam1Overlay"
            style={{ backgroundColor: team1Color }}
          />
          <div
            className="ThreePointerTeam2Overlay"
            style={{ backgroundColor: team2Color }}
          />
          <div className="ThreePointerCardContent">
            <div className="ThreePointerHintText">↺ Tap to flip</div>
            <div className="ThreePointerMatchupText">
              <div className="LeftTeam">{teams.team1}</div>
              <div className="AtSign">@</div>
              <div className="RightTeam">{teams.team2}</div>
            </div>
            <div className="ThreePointerGameTime">{gameTime}</div>
          </div>
        </div>
      </animated.div>

      {/* Back Side */}
      <animated.div
        className={`ThreePointerBackSide ${!isFlipped ? "IgnorePointer" : ""}`}
        style={{ opacity, transform, rotateY: "180deg" }}
      >
        <div className="ThreePointerCard">
          <div className="ThreePointerCardContentBack">
            <div className="ThreePointerPlayerImageWrapper">
              <img
                src={effectiveImage}
                alt={playerName}
                className="ThreePointerPlayerImage"
              />
            </div>
            <div className="ThreePointerPlayerName">{playerName}</div>
            <div className="ThreePointerAiScore">AI Score: {aiScore}</div>
            <div className="ThreePointerGameTime">
              {gameTime} ({gameStatus})
            </div>
            {propText && (
              <div className="ThreePointerPropText">{propText}</div>
            )}
          </div>
        </div>
      </animated.div>
    </div>
  );
}

export default ThreePointerFlippableCard;
