// src/components/NBAThreePointerPredictions.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import ThreePointerFlippableCard from "./ThreePointerFlippableCard";
import "./NBAThreePointerPredictions.css";

const DEFAULT_PROFILE_URL = "https://s3.amazonaws.com/37assets/svn/765-default-avatar.png";

// Helper: Get Eastern date string (YYYYMMDD)
const getEasternDate = () => {
  const now = new Date();
  const etOptions = { timeZone: "America/New_York", year: "numeric", month: "2-digit", day: "2-digit" };
  const [month, day, year] = now.toLocaleDateString("en-US", etOptions).split("/");
  return `${year}${month}${day}`;
};

// Helper: Try multiple key variants to get the player image
const findPlayerImage = (fullName, playerMap) => {
  if (!fullName) return DEFAULT_PROFILE_URL;
  try {
    // Remove periods and extra spaces.
    const cleaned = fullName.replace(/\./g, "").trim();
    const parts = cleaned.split(" ");
    if (parts.length === 0) return DEFAULT_PROFILE_URL;
    const firstInitial = parts[0][0].toLowerCase();
    const lastName = parts[parts.length - 1].toLowerCase();
    const key1 = `${lastName}-${firstInitial}`;
    if (playerMap[key1]) return playerMap[key1];
    // Try key using lastName only.
    const key2 = lastName;
    if (playerMap[key2]) return playerMap[key2];
    // Try full name without spaces.
    const key3 = fullName.toLowerCase().replace(/\s+/g, "");
    if (playerMap[key3]) return playerMap[key3];
    return DEFAULT_PROFILE_URL;
  } catch (e) {
    console.warn("findPlayerImage error:", fullName, e);
    return DEFAULT_PROFILE_URL;
  }
};

export default function NBAThreePointerPredictions() {
  const [predictions, setPredictions] = useState([]);
  const [playerMap, setPlayerMap] = useState({});
  const [gameTimes, setGameTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // Fetch game times from ESPN API.
  const fetchGameTimes = async () => {
    try {
      const dateString = getEasternDate();
      const response = await axios.get(
        `https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard?dates=${dateString}`
      );
      const games = response.data.events.reduce((acc, game) => {
        const competitions = game.competitions || [];
        competitions.forEach((competition) => {
          const competitors = competition.competitors || [];
          if (competitors.length !== 2) return;
          let home_team = null;
          let away_team = null;
          competitors.forEach((competitor) => {
            const teamInfo = competitor.team || {};
            // Here we assume the ESPN API returns the desired abbreviation.
            const desired_abbr = teamInfo.abbreviation;
            if (competitor.homeAway === "home") {
              home_team = desired_abbr;
            } else if (competitor.homeAway === "away") {
              away_team = desired_abbr;
            }
          });
          if (home_team && away_team) {
            // Use a key format "AWAY-HOME"
            const key = `${away_team}-${home_team}`;
            let start_time = "Time TBD";
            if (competition.date) {
              const dt = new Date(competition.date);
              start_time = dt.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
                timeZone: "America/New_York",
              }) + " ET";
            }
            acc[key] = {
              home_team,
              away_team,
              time: start_time,
              status: competition.status?.type?.description || "Scheduled",
            };
          }
        });
        return acc;
      }, {});
      setGameTimes(games);
    } catch (error) {
      console.error("Error fetching game times:", error);
      setGameTimes({});
    }
  };

  // Check authentication.
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get("/profile/");
        const { access_token } = response.data || {};
        const isSubscribed = !!access_token;
        setIsAuthenticated(isSubscribed);
        if (!isSubscribed) window.location.href = "https://dubclub.win/r/Zeus_Analytics/";
      } catch (error) {
        console.error("Auth error:", error);
        window.location.href = "https://zeusanalytics.org/accounts/dubclub/login/";
      }
    };
    checkAuth();
  }, []);

  // Fetch data (only when authenticated). Removed gameTimes from dependencies to avoid infinite calls.
  useEffect(() => {
    if (!isAuthenticated) return;

    const fetchData = async () => {
      try {
        // Fetch game times.
        await fetchGameTimes();

        // Fetch player pictures.
        const playersResponse = await axios.get("/api/nba-player-pictures/", {
          params: { all: true, sort: "player_name" },
        });
        const newPlayerMap = playersResponse.data.results.reduce((acc, player) => {
          const cleaned = player.player_name.replace(/\./g, "").trim();
          const parts = cleaned.split(" ");
          if (parts.length === 0) return acc;
          const firstName = parts[0];
          const lastName = parts[parts.length - 1];
          const key1 = `${lastName.toLowerCase().replace(/'/g, "")}-${firstName[0].toLowerCase()}`;
          acc[key1] = player.profile_picture;
          const key2 = lastName.toLowerCase().replace(/'/g, "");
          acc[key2] = player.profile_picture;
          const key3 = player.player_name.toLowerCase().replace(/\s+/g, "");
          acc[key3] = player.profile_picture;
          return acc;
        }, {});
        setPlayerMap(newPlayerMap);

        // Fetch three-pointer predictions.
        const predictionsResponse = await axios.get("/api/nba-three-pointer-predictions/");
        const enhanced = predictionsResponse.data.map((pred) => {
          // Build gameKey in the same format as fetched gameTimes ("AWAY-HOME").
          const gameKey = `${pred.away_team}-${pred.home_team}`;
          const gameInfo = gameTimes[gameKey] || { time: "Time TBD", status: "Scheduled" };
          return {
            ...pred,
            profile_picture: findPlayerImage(pred.predicted_player_name, newPlayerMap),
            gameTime: gameInfo.time,
            gameStatus: gameInfo.status,
          };
        });
        setPredictions(enhanced);
      } catch (err) {
        console.error("Data fetch error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated]); // Only depends on isAuthenticated

  if (isAuthenticated === null) {
    return <div className="tpp-loading">Checking subscription...</div>;
  }
  if (loading) {
    return <div className="tpp-loading">Loading three-pointer predictions...</div>;
  }

  return (
    <div className="tpp-container">
      <h1>NBA Three Pointer Predictions</h1>
      <div className="tpp-grid">
        {predictions.map((pred) => (
          <div key={pred.id} className="tpp-card-wrapper">
            <ThreePointerFlippableCard
              teams={{ team1: pred.away_team, team2: pred.home_team }}
              playerName={pred.predicted_player_name}
              playerImage={pred.profile_picture}
              aiScore={pred.ai_score?.toFixed(2) || "N/A"}
              gameTime={pred.gameTime}
              gameStatus={pred.gameStatus}
              propText={pred.prop_text}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
