// src/components/NbaThreePointerHub.jsx
import React from 'react';
import NBAThreePointerPredictions from './NBAThreePointerPredictions';
import './NbaThreePointerHub.css';

function NbaThreePointerHub() {
  return (
    <div className="three-pointer-hub">
      <NBAThreePointerPredictions />
    </div>
  );
}

export default NbaThreePointerHub;
